import React, { useState, useEffect } from "react";

const NavMenu: React.FC = () => {
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const [logoutUrl, setLogoutUrl] = useState<string>("/bff/logout");
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const fetchIsUserLoggedIn = async () => {
		try {
			const response = await fetch("/bff/user", {
				headers: {
					"X-CSRF": "1",
				},
			});

			if (response.ok && response.status === 200) {
				const data = await response.json();
				const fetchedLogoutUrl =
					data.find((claim: any) => claim.type === "bff:logout_url")?.value ??
					logoutUrl;
				setLoggedIn(true);
				setLogoutUrl(fetchedLogoutUrl);
			} else {
				setLoggedIn(false);
			}
		} catch (e) {
			console.error(e);
			setLoggedIn(false);
		}
	};

	useEffect(() => {
		fetchIsUserLoggedIn();
	}, []);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<header className='fixed top-0 left-0 right-0 z-50 shadow-md'>
			<nav className='h-14 bg-white text-gray-700 border-b border-gray-200'>
				<div className='container mx-auto flex justify-between items-center h-full px-4'>
					{/* Logo */}
					<a href='/'>
						<img
							src='greatair-logotipo.svg'
							alt='GreatAir Logo'
							className='h-10'
						/>
					</a>

					{/* Menu Toggle Button */}
					<button
						className='text-gray-500 hover:text-gray-600 md:hidden focus:outline-none'
						onClick={toggleMenu}>
						<svg
							className='w-6 h-6'
							fill='none'
							stroke='currentColor'
							viewBox='0 0 24 24'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth={2}
								d={
									isMenuOpen
										? "M6 18L18 6M6 6l12 12"
										: "M4 6h16M4 12h16m-16 6h16"
								}
							/>
						</svg>
					</button>

					{/* Navigation Links */}
					<div
						className={`absolute top-full left-0 right-0 md:relative md:top-0 ${
							isMenuOpen ? "flex" : "hidden"
						} md:flex flex-col md:flex-row items-center gap-4 pb-4 md:pb-0 bg-white md:bg-transparent`}>
						<a
							href='/'
							className='mt-2 md:mt-0 hover:text-blue-500 transition-colors duration-200'>
							Home
						</a>
						{loggedIn && (
							<>
								<a
									href='/Report'
									className='mt-2 md:mt-0 hover:text-blue-500 transition-colors duration-200'>
									Informe
								</a>
								<a
									href='/CO2Map'
									className='mt-2 md:mt-0 hover:text-blue-500 transition-colors duration-200'>
									CO&#8322; Mapa
								</a>
								<a
									href='/Comparator'
									className='mt-2 md:mt-0 hover:text-blue-500 transition-colors duration-200'>
									Comparador
								</a>
								<a
									href='/Dashboard'
									className='mt-2 md:mt-0 hover:text-blue-500 transition-colors duration-200'>
									Dashboard
								</a>
							</>
						)}
						<a
							href='/CO2Data'
							className='mt-2 md:mt-0 hover:text-blue-500 transition-colors duration-200'>
							CO&#8322; Datos
						</a>
						<a
							href={loggedIn ? logoutUrl : "/bff/login"}
							className='mt-2 md:mt-0 hover:text-blue-500 transition-colors duration-200'>
							{loggedIn ? "Logout" : "Login"}
						</a>
					</div>
				</div>
			</nav>
		</header>
	);
};

export { NavMenu };
