import { CO2Info } from "./fetchCO2ByBusiness";

interface MeanData {
	meanCO2: number;
	meanTemperature: number;
	meanHumidity: number;
}

const calculateMeanData = (data: CO2Info[]): MeanData => {
	const totalCO2 = data.reduce((total, item) => total + item.cO2, 0);
	const totalTemperature = data.reduce(
		(total, item) => total + item.temperature,
		0
	);
	const totalHumidity = data.reduce((total, item) => total + item.humidity, 0);

	const meanCO2 = Math.round(totalCO2 / data.length);
	const meanTemperature = parseFloat(
		(totalTemperature / data.length).toFixed(2)
	);
	const meanHumidity = Math.round(totalHumidity / data.length);

	return { meanCO2, meanTemperature, meanHumidity };
};

export { calculateMeanData, MeanData };
