import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label } from 'recharts';
import { calculateAverageCO2ByBusinessType } from '../services/businessService';
import { calculateAverageCO2ByArea } from '../services/businessService';

const COLORS = ['#008000', '#006400', '#9ACD32', '#32CD32', '#7CFC00'];

const Dashboard: React.FC = () => {
    const [businessData, setBusinessData] = useState<any[]>([]);
    const [areaData, setAreaData] = useState<any[]>([]);
    const [startDateBusinessType, setStartDateBusinessType] = useState('');
    const [endDateBusinessType, setEndDateBusinessType] = useState('');
    const [startDateArea, setStartDateArea] = useState('');
    const [endDateArea, setEndDateArea] = useState('');

    useEffect(() => {
        const fetchTypeOfBusinessData = async () => {
            const businessDataResult = await calculateAverageCO2ByBusinessType(startDateBusinessType, endDateBusinessType);
            setBusinessData(businessDataResult);
        };

        const fetchAreaData = async () => {
            const areaDataResult = await calculateAverageCO2ByArea("2024-01-01", "2024-02-01");
            setAreaData(areaDataResult);
        };

        if (startDateBusinessType !== '' && endDateBusinessType !== '')
        { 
            fetchTypeOfBusinessData();
        }
        if (startDateArea !== '' && endDateArea !== '') {
            fetchAreaData();
        }
    }, [startDateBusinessType, endDateBusinessType, startDateArea, endDateArea]);

    return (
        <>
            <div className='w-3/4 mx-auto p-5 flex justify-center'>
                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <div className="flex items-center justify-center">
                        <h1 className="text-slate-500 text-2xl pb-6">
                            Volumen C02 por tipo de negocio
                        </h1>
                    </div>
                        <div className="grid grid-cols-2 gap-4">
                        <div className="bg-slate-200 rounded-md p-4">
                                <label className="block">
                                    <span className="text-gray-700">Comienzo Tipo de Negocio:</span>
                                    <input
                                        type="date"
                                        className="mt-1 block  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        value={startDateBusinessType}
                                        onChange={(e) => setStartDateBusinessType(e.target.value)}
                                    />
                                </label>
                                                      </div>
                        <div className="bg-slate-200 rounded-md p-4">
                                <label className="block">
                                    <span className="text-gray-700">Final Tipo de negocio:</span>
                                    <input
                                        type="date"
                                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        value={endDateBusinessType}
                                        onChange={(e) => setEndDateBusinessType(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                    <div className="flex items-center justify-center">
            {businessData.length > 0 &&
                        <PieChart width={800} height={800}>
                            <Pie
                                data={businessData}
                                cy={250}
                                labelLine={false}
                                outerRadius={200}
                                fill="#8884d8"
                                dataKey="averageCO2"
                                label={({ typeOfBusiness, percent }) => `${typeOfBusiness} ${(percent * 100).toFixed(0)}%`}
                            >
                                {businessData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            </PieChart>}
                    </div>

                    <div className="flex items-center justify-center">
                        <h1 className="text-slate-500 text-2xl pb-6">
                            Volumen C02 por area de negocio
                        </h1>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div className="bg-slate-200 rounded-md p-4">
                            <label className="block">
                                <span className="text-gray-700">Comienzo area de Negocio:</span>
                                <input
                                    type="date"
                                    className="mt-1 block  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={startDateArea}
                                    onChange={(e) => setStartDateArea(e.target.value)}
                                />
                            </label>
                        </div>
                        <div className="bg-slate-200 rounded-md p-4">
                            <label className="block">
                                <span className="text-gray-700">Final area de negocio:</span>
                                <input
                                    type="date"
                                    className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    value={endDateArea}
                                    onChange={(e) => setEndDateArea(e.target.value)}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">

                                      {areaData.length > 0 &&
                        <PieChart width={800} height={800}>
                            <Pie
                                data={areaData}
                                cy={250}
                                labelLine={false}
                                outerRadius={200}
                                fill="#8884d8"
                                dataKey="averageCO2"
                                label={({ area, percent }) => `${area} ${(percent * 100).toFixed(0)}%`}
                            >
                                {
                                    areaData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                }
                            </Pie>
                        </PieChart>
                        }
                    </div>

                </div>
              </div>
        </>
    );
};

export default Dashboard;
