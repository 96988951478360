import React from "react";
import { NavMenu } from "./NavMenu";

interface Props {
	children?: React.ReactNode;
}

const Layout = ({ children }: Props) => {
	return (
		<>
			<NavMenu />
			<main className='mt-14'>{children}</main>
		</>
	);
};
export { Layout };
