import React, { useState, useMemo, useRef, useEffect } from "react";
import { fetchBusinesses, BusinessLocation } from "../utils/fetchBusinesses";

interface SearchBarProps {
	onSearchSubmit: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearchSubmit }) => {
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [businesses, setBusinesses] = useState<BusinessLocation[]>([]);

	useEffect(() => {
		fetchBusinesses()
			.then(setBusinesses)
			.catch((error) => console.error("Failed to fetch Businesses:", error));
	}, []);

	const filteredBusinesses = useMemo(() => {
		console.log("Memo")

		return businesses.filter((business) =>
			business.name.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [searchTerm, businesses]);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("Typing")

		setSearchTerm(event.target.value);
		setIsDropdownOpen(true);
	};

	const handleDropdownSelect = (businessName: string) => {
		console.log("Selecting")
		setSearchTerm(businessName);
		setIsDropdownOpen(false);
		onSearchSubmit(businessName);
	};

	const handleSearchIconClick = () => {
		console.log("clicking search")

		const isExactMatch = businesses
			.map((business) => business.name.toLowerCase())
			.includes(searchTerm.toLowerCase());

		if (isExactMatch) {
			onSearchSubmit(searchTerm);
		}
		setIsDropdownOpen(false);
	};

	//Close Dropdown on losing focus-----------
	const blurTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	const handleInputBlur = () => {
		console.log("blur")
		if (blurTimeoutRef.current) {
			clearTimeout(blurTimeoutRef.current);
		}

		blurTimeoutRef.current = setTimeout(() => {
			setIsDropdownOpen(false);
		}, 100);
	};

	useEffect(() => {
		return () => {
			if (blurTimeoutRef.current) {
				clearTimeout(blurTimeoutRef.current);
			}
		};
	}, []);
	//------------------------------------------

	return (
		<div className='pb-4 bg-white w-80'>
			<div className='relative'>
				<input
					type='text'
					id='table-search'
					autoComplete='off'
					className='pr-10 pl-2 p-2 text-sm text-gray-900 border border-gray-300 rounded-lg w-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500'
					placeholder='Buscar comercio'
					onChange={handleInputChange}
					value={searchTerm}
					onFocus={() => setIsDropdownOpen(true)}
					onBlur={handleInputBlur}
				/>
				<div
					className='w-5 h-5 absolute top-2 right-3 text-gray-500 cursor-pointer hover:text-gray-700'
					onClick={handleSearchIconClick}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-5 h-5'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
						/>
					</svg>
				</div>
			</div>
			{isDropdownOpen && (
				<div className='absolute w-80 bg-white border border-gray-300 rounded-lg z-50 max-h-40 overflow-y-auto'>
					{filteredBusinesses.map((business, index) => (
						<div
							key={index}
							className='p-2 hover:bg-gray-100 cursor-pointer'
							onClick={() => handleDropdownSelect(business.name)}>
							{business.name}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default SearchBar;
