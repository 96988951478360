import React from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { CO2Map } from "./components/CO2Map";
import { CO2Data } from "./components/CO2Data";
import { Report } from "./components/Report";
import Comparator from "./components/Comparator";

import "./custom.css";
import  Dashboard  from "./components/Dashboard";

const App = () => {
	return (
		<Layout>
			<Route exact path='/' component={Home} />
			<Route path='/CO2Map' component={CO2Map} />
			<Route path='/CO2Data' component={CO2Data} />
			<Route path='/Report' component={Report} />
			<Route path='/Comparator' component={Comparator} />
			<Route path='/Dashboard' component={Dashboard} />
		</Layout>
	);
};

export default App;
