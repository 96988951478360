const API_KEY = "5d43aa73a98c4c5d953e41c677a2b3ff";

export const comparatorByBusiness = async (business: string, start1: string, end1: string, start2: string, end2: string): Promise<any> => {
    try {
        let url = "/api/comparator";
        let comparison =
        {
                "businessName": business,
                "start1": start1,
                "end1": end1,
                "start2": start2,
                "end2": end2
        }
        console.log(comparison);
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-csrf": "1",
                "ocp-apim-subscription-key": API_KEY,
            },
            body: JSON.stringify(comparison),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching devices:', error);
        throw error;
    }
};