const API_KEY = "5d43aa73a98c4c5d953e41c677a2b3ff";

export const calculateAverageCO2ByBusinessType = async (start:string, end:string): Promise<any[]> => {
    try {
        let url = `/api/CalculateAverageCO2ByBusinessType/${start}/${end}`;
        
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-csrf": "1",
                "ocp-apim-subscription-key": API_KEY,
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Error fetching devices:', error);
        throw error;
    }
};

export const calculateAverageCO2ByArea = async (start: string, end: string): Promise<any[]> => {
    try {
        let url = `/api/CalculateAverageCO2ByArea/${start}/${end}`;

        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-csrf": "1",
                "ocp-apim-subscription-key": API_KEY,
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Error fetching devices:', error);
        throw error;
    }
};