import React, { useState, useEffect } from 'react';
import { comparatorByBusiness } from '../services/comparatorService';
import { BusinessLocation, fetchBusinesses } from '../utils/fetchBusinesses';

const Comparator: React.FC = () => {
    const [startDate1, setStartDate1] = useState('');
    const [endDate1, setEndDate1] = useState('');
    const [startDate2, setStartDate2] = useState('');
    const [endDate2, setEndDate2] = useState('');
    const [selectedShop, setSelectedShop] = useState('');
    const [graphs, setGraphs] = useState([]);
    const [establishments, setEstablishments] = useState(["", ""]);

    useEffect(() => {
        fetchBusinesses()
            .then((data) => {
                const businessNames = data.map((item: BusinessLocation) => item.name);
                setEstablishments(businessNames);
            })
            .catch((error) => console.error("Failed to fetch Businesses:", error));
    }, []);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // Handle form submission here
    };

    const handleShopSelection = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedShop(e.target.value);
        const data = await comparatorByBusiness(e.target.value, startDate1, endDate1, startDate2, endDate2); // replace with your actual page and limit
        console.log(e.target.value)
        setGraphs(data);
    };

    return (
        <>
            <div className='w-3/4 mx-auto p-5 flex justify-center'>
                <div className='w-full bg-white rounded-lg shadow-xl p-6'>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="block">
                                    <span className="text-gray-700">Comienzo Primera Comparcion:</span>
                                    <input
                                        type="date"
                                        className="mt-1 block  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        value={startDate1}
                                        onChange={(e) => setStartDate1(e.target.value)}
                                    />
                                </label>
                                <label className="block pt-8">
                                    <span className="text-gray-700">Final Primera Comparacion:</span>
                                    <input
                                        type="date"
                                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        value={endDate1}
                                        onChange={(e) => setEndDate1(e.target.value)}
                                    />
                                </label>
                            </div>
                            <div>
                                <label className="block">
                                    <span className="text-gray-700">Comienzo Segunda Comparcion:</span>
                                    <input
                                        type="date"
                                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        value={startDate2}
                                        onChange={(e) => setStartDate2(e.target.value)}
                                    />
                                </label>
                                <label className="block pt-8">
                                    <span className="text-gray-700">Final Segunda Comparacion:</span>
                                    <input
                                        type="date"
                                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                        value={endDate2}
                                        onChange={(e) => setEndDate2(e.target.value)}
                                    />
                                </label>
                            </div>
                        </div>
                        <label className="block pt-8">
                            <span className={(!startDate1 || !endDate1 || !startDate2 || !endDate2) ? "text-gray-400" : "text-gray-700"} >Comercio:</span>
                            <select
                                className="mt-1 block rounded-md bg-gray-200 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={selectedShop}
                                onChange={handleShopSelection}
                                disabled={!startDate1 || !endDate1 || !startDate2 || !endDate2}

                            >
                                {establishments.map((shop) => (
                                    <option key={shop} value={shop}>
                                        {shop}
                                    </option>
                                ))}
                            </select>
                        </label>

                    </form>
                </div>
            </div>
            <div className='mx-auto p-5 flex justify-center'>
                <div className='w-full bg-white rounded-lg  p-6 flex justify-around'>
                    {graphs.length > 0 && graphs.map((graph, index) => (
                        <img key={index} src={`data:image/png;base64,${graph}`} alt="Graph" style={{ width: '45%' }} />
                    ))}
                </div>
            </div>

        </>
    );
};

export default Comparator;
