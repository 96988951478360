import React from "react";

interface InfoCardProps {
	title: string;
	value: string | Date;
	unit: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ title, value, unit }) => {
	return (
		<div className='bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300'>
			<h3 className='text-md md:text-lg font-semibold text-gray-600 mb-2'>
				{title}
			</h3>
			<p className='text-2xl md:text-3xl font-bold text-black'>{`${value} ${unit}`}</p>
		</div>
	);
};

export default InfoCard;
