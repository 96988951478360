import React from "react";

const Home: React.FC = () => {
	return (
		<div>
			{/* Hero Section */}
			<section className='bg-gradient-to-r from-green-500 to-blue-600 text-white text-center p-20'>
				<h1 className='text-5xl font-bold mb-6'>Great Air</h1>
				<p className='text-2xl mb-6'>
					Revolucionando el control de calidad del aire en espacios comerciales
					con tecnologías IoT.
				</p>
			</section>

			{/* Feature Section */}
			<section className='py-20 px-10 bg-white'>
				<div className='max-w-6xl mx-auto'>
					<h2 className='text-3xl font-bold text-center mb-16'>
						Nuestra Misión
					</h2>
					<div className='grid grid-cols-1 md:grid-cols-3 gap-10 text-center'>
						<div className='p-6 shadow-lg rounded-lg'>
							<h3 className='font-semibold text-xl mb-3'>Sensores Avanzados</h3>
							<p>
								Control de la calidad del aire preciso con nuestra recolección
								de datos en tiempo real.
							</p>
						</div>
						<div className='p-6 shadow-lg rounded-lg'>
							<h3 className='font-semibold text-xl mb-3'>
								Analítica inteligente
							</h3>
							<p>Interpretamos los datos para aplicarlos en el día a día.</p>
						</div>
						<div className='p-6 shadow-lg rounded-lg'>
							<h3 className='font-semibold text-xl mb-3'>
								Integrados en la comunidad
							</h3>
							<p>Participamos en la acción colectiva por una ciudad limpia.</p>
						</div>
					</div>
				</div>
			</section>

			{/* Testimonials Section */}
			<section className='py-20 px-10 bg-white'>
				<div className='max-w-6xl mx-auto'>
					<h2 className='text-3xl font-bold text-center mb-16'>
						Lo Que Dicen Nuestros Usuarios
					</h2>
					<div className='grid grid-cols-1 md:grid-cols-2 gap-10'>
						<blockquote className='p-6 shadow-lg rounded-lg'>
							<p className='mb-4'>
								"Great Air ha transformado la forma en que entendemos la calidad
								del aire de nuestra ciudad. Los datos en tiempo real son
								invaluables para nuestros esfuerzos comunitarios."
							</p>
							<cite>María González, Activista Ambiental</cite>
						</blockquote>
						<blockquote className='p-6 shadow-lg rounded-lg'>
							<p className='mb-4'>
								"Como padre, saber la calidad del aire alrededor de las escuelas
								de mis hijos me da tranquilidad. Esta plataforma es un cambio de
								juego."
							</p>
							<cite>David Ruiz, Padre Preocupado</cite>
						</blockquote>
					</div>
				</div>
			</section>

			{/* Call to Action Section */}
			<section className='bg-gradient-to-r from-green-500 to-blue-600 text-white text-center p-20'>
				<h2 className='text-4xl font-bold mb-6'>
					Únete al Movimiento por un Aire Limpio
				</h2>
				<p className='text-2xl mb-6'>
					Juntos, podemos crear una ciudad más saludable y sostenible.
				</p>
				<div className='flex justify-center items-center space-x-4 mt-8'>
					<img
						src='greatair-logotipo.svg'
						alt='GreatAir Logo'
						className='h-12'
					/>
					<img
						src='Ayuntamiento_de_Zaragoza_logo.png'
						alt='Ayuntamiento de Zaragoza Logo'
						className='h-12'
					/>
				</div>
			</section>

			{/* Footer */}
			<footer className='bg-gray-800 text-white text-center p-3'>
				<p>© 2024 GreatAir. All rights reserved.</p>
			</footer>
		</div>
	);
};

export { Home };
