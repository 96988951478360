import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import InfoCard from "./InfoCard";
import { fetchBusinesses, BusinessLocation } from "../utils/fetchBusinesses";
import { fetchCO2DataByBusiness, CO2Info } from "../utils/fetchCO2ByBusiness";
import { calculateMeanData, MeanData } from "../utils/calculateMeanData";
import { enterFullScreen } from "../utils/fullscreen";

const CO2Data: React.FC = () => {
	const [businesses, setBusinesses] = useState<BusinessLocation[]>([]);
	const [selectedBusiness, setSelectedBusiness] =	useState<BusinessLocation | null>(null);
	const [co2Data, setCO2Data] = useState<CO2Info[] | null>(null);
	const [meanData, setMeanData] = useState<MeanData | null>(null);
	const [showMeanData, setShowMeanData] = useState(true);

	useEffect(() => {
		fetchBusinesses()
			.then(setBusinesses)
			.catch((error) => console.error("Failed to fetch Businesses:", error));
	}, []);

	useEffect(() => {

		const fetchCO2Data = () => {

			if (selectedBusiness) {
				console.log(selectedBusiness.name)

				fetchCO2DataByBusiness(selectedBusiness.name)
					.then((data) => {
						setCO2Data(data);
						console.log(data);
						setMeanData(calculateMeanData(data));
					})
					.catch((error) => console.error("Failed to fetch CO2 data:", error));
			}
		};

		fetchCO2Data();

		const intervalId = setInterval(fetchCO2Data, 15 * 60 * 1000);

		return () => clearInterval(intervalId);
	}, [selectedBusiness]);

	const handleSearchSubmit = (searchTerm: string) => {
		console.log("handleSearchSubmit")
		const matchedBusiness = businesses.find(
			(business) => business.name.toLowerCase() === searchTerm.toLowerCase()
		);
		setSelectedBusiness(matchedBusiness || null);
		enterFullScreen();
	};

	return (
		<div
			className='flex flex-col items-center justify-center h-screen'
			style={{ height: "calc(100dvh - 3.5rem)" }}>
			{!selectedBusiness ? (
				<SearchBar onSearchSubmit={handleSearchSubmit} />
			) : co2Data ? (
				<div className='p-4 w-full space-y-4 overflow-auto text-center'>
					<div className='flex flex-row justify-center gap-2'>
						<button
							onClick={() => {
								setSelectedBusiness(null);
								setShowMeanData(true);
							}}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-6 h-6'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='M15.75 19.5 8.25 12l7.5-7.5'
								/>
							</svg>
						</button>
						<h2 className='text-lg md:text-2xl font-bold'>
							{selectedBusiness.name}
						</h2>
						<button onClick={() => setShowMeanData(!showMeanData)}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={1.5}
								stroke='currentColor'
								className='w-6 h-6'>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									d='m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z'
								/>
							</svg>
						</button>
					</div>
					<div className='flex flex-col gap-3 md:flex-row md:flex-wrap md:justify-center'>
						{!showMeanData ? (
							co2Data.map((data, index) => (
								<div key={index} className='m-2 flex flex-col gap-2'>
									<h3 className='text-lg font-bold mb-2'>
										Sensor {index + 1} ({data.deviceId})
									</h3>
									<InfoCard
										title='Nivel CO2'
										value={data.cO2.toString()}
										unit='ppm'
									/>
									<InfoCard
										title='Temperatura'
										value={data.temperature.toString()}
										unit='°C'
									/>
									<InfoCard
										title='Humedad'
										value={data.humidity.toString()}
										unit='%'
									/>
									<InfoCard
										title='Hora Fecha Recogida'
										value={new Date(
											data.horaFechaRecogida + "Z"
										).toLocaleString("es-ES", { timeZone: "Europe/Madrid" })}
										unit=''
									/>
								</div>
							))
						) : meanData ? (
							<div className='flex flex-col gap-2 justify-center md:w-10/12 w-full self-center'>
								<div className='w-full'>
									<div className='bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300'>
										<h3 className='text-2xl md:text-4xl font-semibold text-gray-600 mb-2'>
											Nivel CO2
										</h3>
										<p className='text-4xl md:text-9xl font-bold text-black'>{`${meanData.meanCO2.toString()} ppm`}</p>
									</div>
								</div>
								<div className='w-full flex flex-col gap-2 lg:flex-row'>
									<div className='w-full lg:w-1/2'>
										<div className='bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300'>
											<h3 className='text-2xl md:text-4xl font-semibold text-gray-600 mb-2'>
												Temperatura
											</h3>
											<p className='text-4xl md:text-9xl font-bold text-black'>{`${meanData.meanTemperature.toString()} °C`}</p>
										</div>
									</div>
									<div className='w-full lg:w-1/2'>
										<div className='bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300'>
											<h3 className='text-2xl md:text-4xl font-semibold text-gray-600 mb-2'>
												Humedad
											</h3>
											<p className='text-4xl md:text-9xl font-bold text-black'>{`${meanData.meanHumidity.toString()} %`}</p>
										</div>
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</div>
	);
};

export { CO2Data };
