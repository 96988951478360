const API_KEY = "5d43aa73a98c4c5d953e41c677a2b3ff";

export interface BusinessLocation {
	latitude: number;
	longitude: number;
	name: string;
	address: string;
}

export const fetchBusinesses = async (): Promise<BusinessLocation[]> => {
	const response = await fetch("/api/businessLocation", {
		headers: {
			"x-csrf": "1",
			"ocp-apim-subscription-key": API_KEY,
		},
	});
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
	console.log(data);
	return data as BusinessLocation[];
};
