export const enterFullScreen = () => {
	if (document.documentElement.requestFullscreen) {
		document.documentElement.requestFullscreen();
	} else if ((document.documentElement as any).mozRequestFullScreen) {
		// Firefox
		(document.documentElement as any).mozRequestFullScreen();
	} else if ((document.documentElement as any).webkitRequestFullscreen) {
		// Chrome, Safari and Opera
		(document.documentElement as any).webkitRequestFullscreen();
	} else if ((document.documentElement as any).msRequestFullscreen) {
		// IE/Edge
		(document.documentElement as any).msRequestFullscreen();
	}
};
