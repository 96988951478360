const API_KEY = "5d43aa73a98c4c5d953e41c677a2b3ff";

export interface CO2Info {
	temperature: number;
	humidity: number;
	cO2: number;
	latitude: number;
	longitude: number;
	deviceId: string;
	horaFechaRecogida: Date;
	jsonMessage: string;
	cO2Baseline: number;
	nativeMessage: string;
	id: number;
	creationDate: Date;
	modificationDate: Date;
	modifyBy: string;
	createdBy: string;
}

export const fetchCO2DataByBusiness = async (
	business: string
): Promise<CO2Info[]> => {
	const url = `/api/CO2byBusiness/${business}`;
	console.log("I am in")

	try {
		const response = await fetch(url, {
			headers: {
				"Content-Type": "application/json",
				"ocp-apim-subscription-key": API_KEY,
				"x-csrf": "1",
			},
		});
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		
		const data = await response.json();
		console.log(data)
		return data as CO2Info[];
	} catch (error) {
		console.error("Failed to fetch CO2 data:", error);
		return [];
	}
};
