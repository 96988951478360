import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { LatLngExpression } from "leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import SearchBar from "./SearchBar";
import InfoCard from "./InfoCard";
import { fetchBusinesses, BusinessLocation } from "../utils/fetchBusinesses";

const iconUrl =
	"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png";
const iconShadowUrl =
	"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png";

let DefaultIcon = L.icon({
	iconUrl: iconUrl,
	shadowUrl: iconShadowUrl,
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const CO2Map: React.FC = () => {
	const zaragozaPosition: LatLngExpression = [41.65606, -0.87734];
	const [businessesLocation, setBusinessesLocation] = useState<
		BusinessLocation[]
	>([]);
	const [selectedBusiness, setSelectedBusiness] = useState<string>("");

	useEffect(() => {
		fetchBusinesses()
			.then(setBusinessesLocation)
			.catch((error) => console.error("Failed to fetch Businesses:", error));
	}, []);

	const handleReset = () => {
		setSelectedBusiness("");
	};

	const handleSearchSubmit = (searchTerm: string) => {
		setSelectedBusiness(searchTerm.toLowerCase());
	};

	const filteredBusinesses = businessesLocation.filter((business) =>
		business.name.toLowerCase().includes(selectedBusiness)
	);
	const selectedBusinessDetails = businessesLocation.find(
		(business) => business.name.toLowerCase() === selectedBusiness
	);

	return (
		<div className='p-4 max-w-dvh'>
			<div className='flex content-center gap-2'>
				<SearchBar onSearchSubmit={handleSearchSubmit} />
				<div>
					<button
						onClick={handleReset}
						className='p-2 text-sm text-gray-400 hover:text-gray-600 border border-gray-300 rounded-lg w-full bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white'>
						Reset
					</button>
				</div>
			</div>
			<div
				className='flex flex-col md:flex-row gap-4 '
				style={{ height: "80vh" }}>
				<div className='flex-grow pt-2'>
					<MapContainer
						center={zaragozaPosition}
						zoom={13}
						style={{
							minHeight: "300px",
							height: "100%",
							width: "100%",
							zIndex: 0,
						}}>
						<TileLayer
							url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
							attribution='&copy; OpenStreetMap contributors'
						/>
						{(selectedBusiness ? filteredBusinesses : businessesLocation).map(
							(business, index) => (
								<Marker
									key={index}
									position={
										[business.latitude, business.longitude] as LatLngExpression
									}
									eventHandlers={{
										mouseover: (event) => event.target.openPopup(),
										mouseout: (event) => event.target.closePopup(),
										click: () => handleSearchSubmit(business.name),
									}}>
									<Popup>
										<b>{business.name}</b>
									</Popup>
								</Marker>
							)
						)}
					</MapContainer>
				</div>
				<div className='w-full md:w-1/3 space-y-4 overflow-auto text-center p-2'>
					{selectedBusinessDetails ? (
						<>
							<InfoCard
								title='Nombre del comercio'
								value={selectedBusinessDetails.name}
								unit=''
							/>
							<InfoCard
								title='Dirección'
								value={selectedBusinessDetails.address}
								unit=''
							/>
						</>
					) : (
						<>
							<InfoCard title='CO2' value='400' unit='ppm' />
							<div className='grid grid-cols-2 gap-4'>
								<InfoCard title='Temperatura' value='25' unit='°C' />
								<InfoCard title='Humedad' value='60' unit='%' />
							</div>
							<InfoCard title='CO2 Diario' value='600' unit='ppm' />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export { CO2Map };
