import React, { useEffect, useState } from "react";
import { fetchBusinesses, BusinessLocation } from "../utils/fetchBusinesses";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface ReportTypeOptions {
	[key: string]: string;
}

const reportTypeOptions: ReportTypeOptions = {
	Diagnostico: "Diagnostic",
	Monitorización: "Monitorization",
	Final: "Final",
};

const Report: React.FC = () => {
	const [establishments, setEstablishments] = useState(["", ""]);
	const [selectedEstablishment, setSelectedEstablishment] =
		useState<string>("");
	const [selectedReportType, setSelectedReportType] = useState<string>("");
	const [additionalInfo, setAdditionalInfo] = useState<string>("");
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");

	useEffect(() => {
		fetchBusinesses()
			.then((data) => {
				const businessNames = data.map((item: BusinessLocation) => item.name);
				setEstablishments(businessNames);
			})
			.catch((error) => console.error("Failed to fetch Businesses:", error));
	}, []);

	const fetchReport = async (
		selectedEstablishment: string,
		requestBody: any
	) => {
		try {
			const response = await fetch(`/api/Report/${selectedEstablishment}`, {
				method: "POST",
				headers: {
					"x-csrf": "1",
					"ocp-apim-subscription-key": "5d43aa73a98c4c5d953e41c677a2b3ff",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(requestBody),
			});
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			// Assuming the response is a PDF file
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);

			// For downloading the PDF file
			const a = document.createElement("a");
			a.href = url;
			a.download = selectedEstablishment + " report.pdf";
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(a);
		} catch (error) {
			console.error("Failed to fetch report:", error);
		}
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const englishReportType = reportTypeOptions[selectedReportType];

		const requestBody = {
			type: englishReportType,
			start: startDate,
			end: endDate,
			conclusion: additionalInfo,
		};
		console.log(requestBody);
		fetchReport(selectedEstablishment, requestBody);
	};

	return (
		<>
			<div className='w-3/4 mx-auto p-5 flex justify-center'>
				<div className='w-full bg-white rounded-lg shadow-xl p-6'>
					<h2 className='text-2xl font-semibold text-center text-gray-800'>
						Generador de Informes
					</h2>
					<form onSubmit={handleSubmit} className='space-y-4 w-full p-5'>
						<div className='flex flex-col space-y-2 max-w-xl mx-auto'>
							<label
								htmlFor='startDate'
								className='text-sm font-semibold text-gray-700'>
								Fecha de inicio
							</label>
							<input
								id='startDate'
								type='date'
								className='form-input p-2 block w-full rounded-lg border border-gray-300'
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
							/>
						</div>

						<div className='flex flex-col space-y-2 max-w-xl mx-auto'>
							<label
								htmlFor='endDate'
								className='text-sm font-semibold text-gray-700'>
								Fecha de finalización
							</label>
							<input
								id='endDate'
								type='date'
								className='form-input p-2 block w-full rounded-lg border border-gray-300'
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
								disabled={!startDate}
							/>
						</div>

						<div className='flex flex-col space-y-2 max-w-xl mx-auto'>
							<label
								htmlFor='reportType'
								className='text-sm font-semibold text-gray-700'>
								Tipo de Informe
							</label>
							<select
								id='reportType'
								className='form-select p-2 block w-full rounded-lg border border-gray-300'
								value={selectedReportType || ""}
								onChange={(e) => setSelectedReportType(e.target.value)}
								disabled={!endDate}>
								<option value='' disabled>
									Selecciona un tipo de informe
								</option>
								{Object.keys(reportTypeOptions).map((key, index) => (
									<option key={index} value={key}>
										{key}
									</option>
								))}
							</select>
						</div>

						<div className='flex flex-col space-y-2 max-w-xl mx-auto'>
							<label
								htmlFor='establishment'
								className='text-sm font-semibold text-gray-700'>
								Comercio
							</label>
							<select
								id='establishment'
								className='form-select p-2 block w-full rounded-lg border border-gray-300'
								value={selectedEstablishment || ""}
								onChange={(e) => setSelectedEstablishment(e.target.value)}
								disabled={!selectedReportType}>
								<option value='' disabled>
									Selecciona un comercio
								</option>
								{establishments.map((source, index) => (
									<option key={index} value={source}>
										{source}
									</option>
								))}
							</select>
						</div>

						<div className='flex flex-col space-y-2 mx-auto'>
							<label
								htmlFor='additionalInfo'
								className='text-sm font-semibold text-gray-700'>
								Información adicional
							</label>
							<ReactQuill
								id='additionalInfo'
								className='form-textarea p-2 w-full min-h-60 rounded-lg border border-gray-300 resize-y overflow-auto'
								value={additionalInfo}
								onChange={setAdditionalInfo}
								readOnly={!selectedEstablishment}
							/>
						</div>

						<div className='text-center mt-4 mx-auto'>
							<button
								className='bg-gradient-to-r from-blue-600 to-green-500 hover:opacity-90 text-white py-2 px-4 rounded-lg'
								disabled={!selectedEstablishment}>
								Generar Informe
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export { Report };
